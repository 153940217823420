import React, { createContext, useContext, useState, useEffect } from "react";
import { backButton } from "@telegram-apps/sdk";

const BackButtonContext = createContext();

export const BackButtonProvider = ({ children }) => {
	const [actions, setActions] = useState([]);

	const addAction = (action) => {
		setActions((prev) => [...prev, action]); // Добавляем новое действие в конец
	};

	const removeAction = (action) => {
		setActions((prev) => prev.filter((a) => a !== action));
	};

	useEffect(() => {
		if (!backButton.isSupported()) return;

		// Функция-обработчик для нажатия кнопки
		const handleBackPress = () => {
			if (actions.length > 0) {
				const lastAction = actions[actions.length - 1]; // Берём последнее действие
				lastAction(); // Выполняем его
				setActions((prev) => prev.slice(0, -1)); // Удаляем последнее действие из стека
			}
		};

		if (actions.length > 0) {
			backButton.onClick(handleBackPress);
			backButton.show();
		} else {
			backButton.offClick(handleBackPress);
			backButton.hide();
		}

		return () => {
			backButton.offClick(handleBackPress);
		};
	}, [actions]);

	return <BackButtonContext.Provider value={{ addAction, removeAction }}>{children}</BackButtonContext.Provider>;
};

export const useBackButton = () => useContext(BackButtonContext);
