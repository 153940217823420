import styled from "styled-components";
import { colors, fullScreenAreaHeight, fullScreenMargin } from "../../styles/Variables.style";

export const Container = styled.div`
	height: ${({ isFullScreenMode }) =>
		isFullScreenMode ? `calc(100vh - ${fullScreenAreaHeight} - ${fullScreenMargin});` : "100vh"};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NotFoundContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.bg};
	width: 100%;
	height: 100%;
`;
