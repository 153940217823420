import React, { useEffect, useMemo } from "react";
import { useTranslation } from "../../contexts/TranslationContext";
import GamesGrid from "../../components/GamesGrid/GamesGrid";
import GameTypes from "../../constants/GameTypes";
import { useInfiniteGamesQuery } from "../../hooks/useQuery/useGamesQuery";

const GamesSection = ({ gameType, providers, category, horizontal }) => {
	const { t } = useTranslation();
	const { data, isFetching, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteGamesQuery(
		gameType,
		providers,
		category,
	);

	const games = data?.pages.flatMap((page) => page.data) || [];

	const errorMessage = useMemo(() => {
		switch (gameType) {
			case GameTypes.FAVOURITE:
				return t("games.favouriteGamesWillBeHere");
			case GameTypes.NEW:
				return t("games.newGamesWillBeHere");
			case GameTypes.POPULAR:
				return t("games.popularGamesWillBeHere");
			case GameTypes.HISTORY:
				return t("games.historyGamesWillBeHere");
			default:
				return null;
		}
	}, [gameType, t]);

	return (
		<GamesGrid
			isLoading={isFetching}
			isError={isError}
			games={games}
			hasNextPage={hasNextPage}
			loadMore={fetchNextPage}
			horizontal={horizontal}
			onRetry={refetch}
			errorMessage={errorMessage}
		/>
	);
};

export default GamesSection;
