import React, { useEffect } from "react";
import { useBackButton } from "../../contexts/BackButton";
import { useFullScreen } from "../../contexts/FullScreenContext";
import { Content, Wrapper } from "./PreviewWrap.style";

const PreviewWrap = ({ onClose, children, zIndex }) => {
	const { addAction, removeAction } = useBackButton();
	const { isFullScreen } = useFullScreen();

	useEffect(() => {
		addAction(onClose);
		return () => removeAction(onClose);
	}, []);

	return (
		<Wrapper zIndex={zIndex}>
			<Content isFullScreenMargin={isFullScreen}>{children}</Content>
		</Wrapper>
	);
};

export default PreviewWrap;
