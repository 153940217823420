import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { getMinDeposit, getRemainingWagering, getUserBalance, getWalletBalances } from "../services/userService";
import { Currency } from "../constants/Currency";
import { useTonAddress } from "@tonconnect/ui-react";

const WalletInfoContext = createContext();

export const WalletInfoProvider = ({ children }) => {
	const [balance, setBalance] = useState(null);
	const [tonWalletBalance, setTonWalletBalance] = useState(0);
	const [usdtWalletBalance, setUsdtWalletBalance] = useState(0);
	const [minWithdraw, setMinWithdraw] = useState(1.62);
	const [minDeposit, setMinDeposit] = useState(1.62);
	const [remainingWagering, setRemainingWagering] = useState(0);
	const [courses, setCourses] = useState({});
	const [isBalanceLoading, setIsBalanceLoading] = useState(true);
	const [isAllLoading, setIsAllLoading] = useState(true);
	const [isWalletBalancesLoading, setIsWalletBalancesLoading] = useState(true);
	const [isWalletOpen, setIsWalletOpen] = useState(false);
	const walletAddress = useTonAddress();

	const fetchTonCourse = useCallback(async () => {
		try {
			const result = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd",
			);
			const data = await result.json();
			const tonToUsd = data["the-open-network"].usd; // Сколько USD за 1 TON
			const usdToTon = tonToUsd ? (1 / tonToUsd).toFixed(2) : "0"; // Сокращаем до 2 знаков

			setCourses({ ...courses, [Currency.TON]: parseFloat(usdToTon) }); // Преобразуем обратно в число
		} catch (error) {
			console.error("Ошибка при получении курса TON:", error);
		}
	}, []);

	const fetchMinDeposit = useCallback(async () => {
		try {
			const result = await getMinDeposit();
			setMinDeposit(result.minDeposit);
			setMinWithdraw(result.minWithdraw);
		} catch (error) {
			console.error("Ошибка при получении минимального депозита:", error);
		}
	}, []);

	const fetchRemainingWagering = useCallback(async () => {
		try {
			const remaining = await getRemainingWagering();
			setRemainingWagering(remaining);
		} catch (error) {
			console.error("Error fetching remaining wagering:", error);
		}
	}, []);

	const fetchBalance = useCallback(async () => {
		try {
			setIsBalanceLoading(true);

			const response = await getUserBalance();
			const fetchedBalance = parseFloat(response.balance);
			if (!isNaN(fetchedBalance)) {
				setBalance(fetchedBalance);
				setIsBalanceLoading(false);
			}
		} catch (error) {
			console.error("Ошибка при загрузке баланса:", error);
		}
	}, []);

	const fetchWalletBalances = useCallback(async () => {
		try {
			setIsWalletBalancesLoading(true);
			const response = await getWalletBalances(walletAddress);
			
			const tonBalance = parseFloat(response.tonBalance);
			const usdtBalance = parseFloat(response.usdtBalance);
	
			setTonWalletBalance(isNaN(tonBalance) ? tonBalance : Number(tonBalance.toFixed(2)));
			setUsdtWalletBalance(isNaN(usdtBalance) ? usdtBalance : Number(usdtBalance.toFixed(2)));
		} catch (error) {
			console.error("Ошибка при загрузке балансов:", error);
		} finally {
			setIsWalletBalancesLoading(false);
		}
	}, [walletAddress]);
	
	useEffect(() => {
		fetchWalletBalances();
	}, [walletAddress]);

	useEffect(() => {
		const fetchData = async () => {
			setIsAllLoading(true);

			await Promise.all([fetchBalance(), fetchRemainingWagering(), fetchMinDeposit(), fetchTonCourse()]);

			setIsAllLoading(false);
		};

		fetchData();
	}, []);

	const calcMinDeposit = useCallback(
		(currencyKey) => {
			if (currencyKey === "USDT" || currencyKey === Currency.USDT) return minDeposit;

			const result = minDeposit * courses[Currency[currencyKey] || currencyKey];
			return Math.ceil(result * 1.05 * 100) / 100;
		},
		[minDeposit, courses],
	);

    const hasMinDeposit = tonWalletBalance >= calcMinDeposit(Currency.TON) || usdtWalletBalance >= calcMinDeposit(Currency.USDT);

	const openWallet = () => {
		setIsWalletOpen(true);
	};

	const closeWallet = () => {
		setIsWalletOpen(false);
	};

	return (
		<WalletInfoContext.Provider
			value={{
				isBalanceLoading,
				isAllLoading,
				isWalletBalancesLoading,
				balance,
				tonWalletBalance,
				usdtWalletBalance,
				hasMinDeposit,
				calcMinDeposit,
				minWithdraw,
				remainingWagering,
				courses,
				fetchBalance,
				fetchWalletBalances,
				openWallet,
				closeWallet,
				isWalletOpen,
			}}
		>
			{children}
		</WalletInfoContext.Provider>
	);
};

export const useWalletInfo = () => useContext(WalletInfoContext);
