import React from "react";
import { Container, InfoContent, Text, Title, WalletButton } from "./NotEnoughFundsModal.style";
import Modal from "../Modal/Modal";
import { useWalletInfo } from "../../contexts/WalletInfoContext";
import { useTranslation } from "../../contexts/TranslationContext";

const NotEnoughFundsModal = ({ isShow, onClose }) => {
	const { openWallet } = useWalletInfo();
	const { t } = useTranslation();
	const handleClose = () => {
		onClose();
		openWallet();
	};
	return (
		<Modal
			isShow={isShow}
			onClose={onClose}
		>
			<Container>
				<InfoContent>
					<Title>{t("notEnoughFunds")}</Title>
					<Text>{t("notEnoughFundsMessage")}</Text>
				</InfoContent>
				<WalletButton onClick={handleClose}>{t("addFunds")}</WalletButton>
			</Container>
		</Modal>
	);
};

export default NotEnoughFundsModal;
