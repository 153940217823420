import React, { useEffect } from "react";
import { DrawerContent, DrawerEl, DrawerTitle, DrawerTitleWrap } from "./Drawer.style";
import { useBackButton } from "../../contexts/BackButton";
import { useFullScreen } from "../../contexts/FullScreenContext";
import { Overlay } from "../../styles/Shared.style";

const Drawer = ({ isOpen, onClose, title, zIndex, direction, isFullScreenMargin = true, children }) => {
	const { addAction, removeAction } = useBackButton();
	const { isFullScreen } = useFullScreen();

	useEffect(() => {
		addAction(onClose);
		return () => removeAction(onClose);
	}, []);

	return (
		<>
			<Overlay
				isShow={isOpen}
				onClick={() => onClose()}
			/>
			<DrawerEl
				isFullScreenMargin={isFullScreen && isFullScreenMargin}
				isOpen={isOpen}
				zIndex={zIndex}
				direction={direction}
			>
				<DrawerContent isFullScreenMargin={isFullScreen && isFullScreenMargin}>
					<DrawerTitleWrap isTitle={title}>
						<DrawerTitle>{title}</DrawerTitle>
					</DrawerTitleWrap>
					{children}
				</DrawerContent>
			</DrawerEl>
		</>
	);
};

export default Drawer;
