import styled from "styled-components";
import { fullScreenAreaHeight } from "../../styles/Variables.style";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - ${fullScreenAreaHeight});
	width: 100%;
	color: white;
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: 500;

	margin-bottom: 10vh;
`;
