import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/Variables.style";

// Анимация появления модального окна
const modalEnter = keyframes`
  from { transform: translate(-50%, -55%) scale(0.9); opacity: 0; }
  to { transform: translate(-50%, -50%) scale(1); opacity: 1; }
`;

// Анимация исчезновения модального окна
const modalExit = keyframes`
  from { transform: translate(-50%, -50%) scale(1); opacity: 1; }
  to { transform: translate(-50%, -45%) scale(0.9); opacity: 0; }
`;

// Контейнер модального окна
export const ModalContainer = styled.div`
	position: fixed;
	z-index: 101;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: ${({ isShow }) => (isShow ? modalEnter : modalExit)} 0.3s ease-out;
	transition: opacity 0.3s ease-out;
	padding: 16px;
	opacity: ${({ isShow }) => (isShow ? 1 : 0)};
	visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
`;

export const ModalContent = styled.div`
	background: ${colors.bg};
	padding: 16px;
	border-radius: 24px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;
