import React, { useState } from "react";
import {
	BalancesWrap,
	BalancesContainer,
	BalanceInfo,
	BalanceInfoTitle,
	Button,
	TonSpaceInfo,
	InfoIcon,
	TonSpaceTextFrame,
	TonSpaceTextWrap,
	TonSpaceTextButtonWrap,
	TonSpaceContentWrap,
	TonSpaceFrameWrap,
	TonSpaceTextFrameSkeleton,
	BalanceInfoLoading,
} from "./BalancesSection.style";
import { Currency } from "../../../constants/Currency";
import CurrencyIcon from "../CurrencyIcon/CurrencyIcon";
import InfoCircleIcon from "../../../assets/infoCircle.svg";
import DrawerMini from "../../Drawer/DrawerMini/DrawerMini";
import { useModalState } from "../../../hooks/useModalState";
import { useWalletInfo } from "../../../contexts/WalletInfoContext";
import { useTonWallet } from "@tonconnect/ui-react";
import { isTelegramWallet } from "../../../utils/helpers/shortenAddress";
import { useTranslation } from "../../../contexts/TranslationContext";

const BalancesSection = ({ currencyKey, handleSendTransaction }) => {
	const { render, active, openModal, closeModal } = useModalState();
	const [isFrameLoading, setIsFrameLoading] = useState(false);
	const { calcMinDeposit, tonWalletBalance, usdtWalletBalance, hasMinDeposit, isWalletBalancesLoading } =
		useWalletInfo();
	const wallet = useTonWallet();
	const { t } = useTranslation();

	const handleOpen = () => {
		setIsFrameLoading(true); // Сбрасываем флаг перед открытием
		openModal();
	};

	const onClickTonSpace = () => {
		closeModal();
		handleSendTransaction();
	};

	return (
		<div>
			<BalancesWrap>
				<BalancesContainer>
					<BalanceInfo>
						<BalanceInfoTitle>
							<CurrencyIcon
								currencyVal={Currency.TON}
								withName
							/>
						</BalanceInfoTitle>
						{isWalletBalancesLoading || isNaN(tonWalletBalance) ? (
							<BalanceInfoLoading />
						) : (
							<span>{tonWalletBalance}</span>
						)}
					</BalanceInfo>
					<BalanceInfo>
						<BalanceInfoTitle>
							<CurrencyIcon
								currencyVal={Currency.USDT}
								withName
							/>
						</BalanceInfoTitle>
						{isWalletBalancesLoading || isNaN(usdtWalletBalance) ? (
							<BalanceInfoLoading />
						) : (
							<span>{usdtWalletBalance}</span>
						)}
					</BalanceInfo>
				</BalancesContainer>
			</BalancesWrap>
			{isTelegramWallet(wallet?.appName) && !hasMinDeposit && (
				<TonSpaceInfo onClick={handleOpen}>
					{t("wallet.tonSpaceDepositNote")}
					<span>
						{t("minDeposit")} {calcMinDeposit(currencyKey)} {currencyKey}
					</span>
					<InfoIcon src={InfoCircleIcon} />
				</TonSpaceInfo>
			)}

			{render && (
				<DrawerMini
					isOpen={active}
					onClose={closeModal}
				>
					<TonSpaceContentWrap>
						<TonSpaceFrameWrap>
							<TonSpaceTextFrame
								src="https://wallet.helpscoutdocs.com/article/4-ton-space#What-is-my-TON-Space-INdLS"
								onLoad={() => setIsFrameLoading(false)}
							/>
							{isFrameLoading && <TonSpaceTextFrameSkeleton />}
						</TonSpaceFrameWrap>
						<TonSpaceTextButtonWrap>
							<Button
								isPrimary
								onClick={onClickTonSpace}
							>
								{isTelegramWallet(wallet?.appName) && !hasMinDeposit
									? t("wallet.depositTonSpace")
									: t("wallet.doDeposit")}
							</Button>
						</TonSpaceTextButtonWrap>
					</TonSpaceContentWrap>
				</DrawerMini>
			)}
		</div>
	);
};

export default BalancesSection;
