import styled, { css, keyframes } from "styled-components";
import { colors } from "../../styles/Variables.style";
import { skeletonAnimation } from "../../styles/Shared.style";

export const NoGamesTitle = styled.h2`
	font-size: 1rem;
	font-weight: 800;

	color: white;
	text-align: left;
	margin-left: 5%;
	margin-bottom: 2vh;
`;

export const GamesGridEl = styled.div`
	display: grid;
	gap: 8px;
	width: 100%;
	padding: 16px;
	${({ isHorizontal }) =>
		isHorizontal
			? `
      grid-template-rows: repeat(3, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: 31.8%;
    `
			: `
      grid-template-columns: repeat(3, 1fr);
  `};
`;

export const GamesGridWrap = styled.div`
	display: flex;
	${({ isHorizontal }) =>
		isHorizontal
			? `
      flex-direction: row;
      overflow-x: auto;
    `
			: `
      flex-direction: column;
  `};
`;

export const LoadingTrigger = styled.div`
	${({ isHorizontal }) =>
		isHorizontal
			? `
      width: 1px;
  `
			: `
      width: 100%;
      height: 1px;
  `};
`;

export const ErrorWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ isHorizontal }) =>
		isHorizontal
			? `
      grid-row: span 3;
  `
			: `
      grid-column: span 3;
  `};
`;

export const GameCard = styled.div`
	position: relative;
	width: 100%;
	padding-top: 133.33%; /* 4:3 aspect ratio */
	background-color: #121429;
	border-radius: 8px;
	overflow: hidden;
	transition: transform 0.3s ease;
	cursor: pointer;

	&:hover {
		transform: scale(1.05);
	}
`;

const gameImageStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
`;

export const GameImage = styled.img`
	${gameImageStyle};
`;

export const LoadingGame = styled.div`
	${gameImageStyle};
	${skeletonAnimation};
`;

export const NoGamesMessage = styled.div`
	background: #ffffff0f;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-size: 28px;
	font-weight: 800;
	line-height: 36px;
	text-align: center;
	grid-column: span 3;
	grid-row: span 3;
	height: 100vw;
	padding: 16px;

	div {
		margin-top: 8px;

		opacity: 0.6;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-align: center;
	}
`;
