import {
	backButton,
	closingBehavior,
	init,
	miniApp,
	mockTelegramEnv,
	parseInitData,
	setMiniAppHeaderColor,
	swipeBehavior,
	viewport,
} from "@telegram-apps/sdk";

const TelegramInit = async () => {
	try {
		await init();
	} catch {
		if (process.env.REACT_APP_ENV === "DEV" && process.env.REACT_APP_DEV_TELEGRAM_INIT_DATA) {
			setMockInitData();
		}
	}

	if (backButton.mount.isAvailable()) {
		backButton.mount();
	}

	if (closingBehavior.mount.isAvailable()) {
		closingBehavior.mount();
	}

	if (closingBehavior.enableConfirmation.isAvailable()) {
		closingBehavior.enableConfirmation();
	}

	if (swipeBehavior.mount.isAvailable()) {
		swipeBehavior.mount();
	}

	if (viewport.mount.isAvailable()) {
		await viewport.mount();
		viewport.expand();
	}

	if (miniApp.mount.isAvailable()) {
		miniApp.mount();
		setMiniAppHeaderColor("#111B30");
	}
};

const setMockInitData = () => {
	mockTelegramEnv({
		themeParams: {
			accentTextColor: "#6ab2f2",
			bgColor: "#17212b",
			buttonColor: "#5288c1",
			buttonTextColor: "#ffffff",
			destructiveTextColor: "#ec3942",
			headerBgColor: "#17212b",
			hintColor: "#708499",
			linkColor: "#6ab3f3",
			secondaryBgColor: "#232e3c",
			sectionBgColor: "#17212b",
			sectionHeaderTextColor: "#6ab3f3",
			subtitleTextColor: "#708499",
			textColor: "#f5f5f5",
		},
		initData: {
			user: {
				id: 99281932,
				firstName: "Andrew",
				lastName: "Rogue",
				username: "rogue",
				languageCode: "ru",
				isPremium: true,
				allowsWriteToPm: true,
			},
			hash: "89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31",
			authDate: new Date(1716922846000),
			signature: "abc",
			startParam: "debug",
			chatType: "sender",
			chatInstance: "8428209589180549439",
		},
		initDataRaw: new URLSearchParams([
			[
				"user",
				JSON.stringify({
					id: 99281932,
					first_name: "Andrew",
					last_name: "Rogue",
					username: "rogue",
					language_code: "ru",
					is_premium: true,
					allows_write_to_pm: true,
				}),
			],
			["hash", "89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31"],
			["auth_date", "1716922846"],
			["start_param", "debug"],
			["signature", "abc"],
			["chat_type", "sender"],
			["chat_instance", "8428209589180549439"],
		]).toString(),
		version: "7.2",
		platform: "ios",
	});
};

export default TelegramInit;
