import React, { useState, useEffect, useRef } from "react";
import {
	MainContainer,
	SearchContainer,
	RandomButton,
	RandomIcon,
	NoGamesFoundContainer,
	NoGamesText,
	CheckText,
	SearchTitle,
	SearchGamesWrap,
	SearchInputWrap,
} from "./Search.styles";
import GameTypes from "../../constants/GameTypes";
import GamesGrid from "../../components/GamesGrid/GamesGrid";
import Input from "../Input/Input";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import RandomIconImg from "../../assets/random.svg";
import { useGame } from "../../contexts/GameContext";
import GamesSection from "../GamesSection/GamesSection";
import { useTranslation } from "../../contexts/TranslationContext";
import { useSearchGamesQuery } from "../../hooks/useQuery/useGamesQuery";
import { getRandomGame } from "../../services/gamesService";

const SearchGames = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchInptVal, setSearchInptVal] = useState("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const { t } = useTranslation();
	const timerRef = useRef(null);
	const [randomLoading, setRandomLoading] = useState(false);
	const { setGame, openGame } = useGame();

	const { data: searchedGames, isLoading: isSearchedLoading } = useSearchGamesQuery(searchQuery);

	const handleSearchQuery = (e) => {
		let value = e.target.value;
		setSearchInptVal(value);

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(() => {
			setSearchQuery(value);
		}, 400);
	};

	const handleRandomGameClick = async () => {
		try {
			setRandomLoading(true);
			const randomGame = await getRandomGame();
			setRandomLoading(false);
			setGame(randomGame);
			openGame();
		} catch (error) {
			setRandomLoading(false);
			console.error("Error random game: " + error);
		}
	};

	const handleSelectGame = (game) => {
		setGame(game);
		openGame();
	};

	return (
		<MainContainer noResults={!isSearchedLoading && searchQuery && searchedGames?.length === 0}>
			<SearchContainer>
				<SearchInputWrap>
					<Input
						type="text"
						value={searchInptVal}
						onFocus={() => setIsDropdownOpen(true)}
						onBlur={() => setIsDropdownOpen(false)}
						onChange={handleSearchQuery}
						placeholder={t("search.enterGameName")}
					/>
					<SearchDropdown
						isDropdownOpen={isDropdownOpen}
						searchedGames={searchedGames}
						isSearchedLoading={isSearchedLoading}
						handleSelectGame={handleSelectGame}
					/>
				</SearchInputWrap>
				<RandomButton
					disabled={randomLoading}
					onClick={handleRandomGameClick}
				>
					<RandomIcon
						src={RandomIconImg}
						alt="Random Game"
					/>
					{t("search.randomGame")}
				</RandomButton>
			</SearchContainer>

			{searchQuery && (
				<>
					{!isSearchedLoading && searchedGames?.length === 0 ? (
						<NoGamesFoundContainer>
							<NoGamesText>{t("search.noResults")}</NoGamesText>
							<CheckText>{t("search.checkSpelling")}</CheckText>
						</NoGamesFoundContainer>
					) : (
						<>
							<SearchTitle>{t("search.results")}</SearchTitle>
							<GamesGrid
								isLoading={isSearchedLoading}
								games={searchedGames}
							/>
						</>
					)}
				</>
			)}

			{!searchQuery && (
				<>
					<SearchTitle>{t("popularGames")}</SearchTitle>
					<GamesSection gameType={GameTypes.POPULAR} />
				</>
			)}
		</MainContainer>
	);
};

export default SearchGames;
