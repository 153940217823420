import styled from "styled-components";
import { colors, sizes } from "../../styles/Variables.style";

export const FooterContainer = styled.footer`
	width: 100%;
	position: relative;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 16vh;
`;

export const WarnImage = styled.img`
	width: 50px;
	height: 50px;
	margin: 16px 0;
	display: block;
`;

export const SupportButtons = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	max-width: 100%;
	padding: 16px 16px 0;
`;

export const SupportButton = styled.div`
	width: 100%;
	overflow: hidden;
	padding: 8px 18px 8px 16px;
	border-radius: 12px;
	background: rgba(255, 255, 255, 0.06);
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	transition: transform 0.3s ease;

	&::after {
		content: "";
		position: absolute;
		top: -43px;
		left: -38px;
		width: 76px;
		height: 76px;
		opacity: 0.4;
		background: ${({ decorBackground }) => decorBackground};
		filter: blur(37px);
		border-radius: 50%;
		z-index: 1;
	}

	&:hover {
		transform: scale(1.03);
	}
`;

export const SupportButtonContent = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	z-index: 2;
`;

export const ButtonDecorLine = styled.div`
	position: absolute;
	top: 50%;
	left: -4px;
	transform: translateY(-50%);
	width: 8px;
	height: 38px;
	border-radius: 8px;
	background: ${({ background }) => background};
	z-index: 2;
`;

export const ButtonTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;

	h3 {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
	}

	span {
		font-weight: 400;
		font-size: 10px;
		line-height: 14px;
		color: rgba(255, 255, 255, 0.6);
	}
`;

export const SupportIcon = styled.img`
	width: 38px;
	height: 38px;
`;

export const DocumentLinks = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	width: 100%;
	padding: 48px ${sizes.padding} 0;
`;

export const DocumentLink = styled.a`
	color: ${colors.white};
	text-decoration: none;
	font-size: 14px;

	display: flex;
	justify-content: space-between;
	width: 100%;

	&:hover {
		text-decoration: underline;
	}
`;

export const ArrowIcon = styled.img`
	width: 18px;
	height: 18px;
`;

export const FooterText = styled.p`
	font-size: 10px;

	padding: 0 ${sizes.padding};
	line-height: 14px;
	opacity: 0.6;
	text-align: left;
`;
