import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { transactionsOnPageLimit } from "../constants/Settings";
import getInitData from "../utils/getInitData";
const serverUrl = process.env.REACT_APP_BACKEND_URL;

const { initDataRaw, initData } = getInitData();

const fetchWithAuth = async (url, options = {}) => {
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: `Bearer ${initDataRaw}`,
		...options.headers,
	};

	let response = await fetch(serverUrl + url, { ...options, headers });

	if (!response.ok) {
		// Читаем тело ошибки и кидаем исключение
		const errorText = await response.text();
		const errorMessage = `Ошибка ${response.status}: ${errorText}`;
		console.error(errorMessage);
		throw new Error(errorMessage);
	}

	return await response.json();
};

const userIP = await fetch("https://api64.ipify.org?format=json")
	.then((res) => res.json())
	.then((data) => data.ip);

const getUserBalance = async () => {
	return await fetchWithAuth(`/api/users/balance`, { headers: { "X-User-IP": userIP } });
};

const verifyTransaction = async (boc, senderAddress, amount, transactionType, currency) => {
	return await fetchWithAuth(`/api/transactions/verify-transaction`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ boc, senderAddress, amount, transactionType, currency }),
	});
};

const getMinDeposit = async () => {
	return await fetchWithAuth(`/api/users/min-deposit`, {});
};

const getRemainingWagering = async () => {
	const data = await fetchWithAuth(`/api/users/get-wagering`, {});
	return data.remainingWagering;
};

const getTransactions = async (filter, page, signal) => {
	const params = new URLSearchParams();

	if (filter !== "all") {
		params.append("type", filter);
	}

	params.append("page", page);
	params.append("limit", transactionsOnPageLimit);
	const queryString = params.toString();

	return await fetchWithAuth(`/api/transactions/history?${queryString}`, { signal });
};

const getNavigationMenuItems = async () => {
	return await fetchWithAuth(`/api/users/navigation-menu`, {});
};

const getNavigationMenuItemById = async (id) => {
	return await fetchWithAuth(`/api/users/navigation-menu/${id}`, {});
};

const getActiveSlider = async () => {
	return await fetchWithAuth(`/api/banners/activeBanner`, {});
};

const sendUtmMark = async () => {
	if (initData.startParam) {
		try {
			await fetchWithAuth(`/api/users/utm/increment`, {
				method: "POST",
			});
		} catch {}
	}
};

const getWalletBalances = async (walletAddress) => {
	if (!walletAddress) {
		throw new Error("Неверный формат адреса");
	}
	return await fetchWithAuth(`/api/users/wallet-balance`, {
		method: "POST",
		body: JSON.stringify({ walletAddress }),
	});
};

export {
	fetchWithAuth,
	getUserBalance,
	getMinDeposit,
	getRemainingWagering,
	verifyTransaction,
	getNavigationMenuItems,
	getNavigationMenuItemById,
	getTransactions,
	sendUtmMark,
	getActiveSlider,
	getWalletBalances,
};
