import React, { useState, useEffect, act, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GamesContainer, GamesWrap } from "./Games.styles";
import TabsNavigation from "../../components/TabsNavigation/TabsNavigation";
import Footer from "../../components/Footer/Footer";
import ProvidersSection from "../../components/ProvidersSection/ProvidersSection";
import { useProviders } from "../../contexts/ProvidersContext";
import GameTypes from "../../constants/GameTypes";
import useTelegram from "../../hooks/useTelegram";
import { SectionTitle } from "../../styles/Shared.style";
import { useNavTabs } from "../../contexts/TabsNavigationContext";
import GamesSection from "../../components/GamesSection/GamesSection";
import { Language } from "../../utils/i18n/getTranslation";
import { useBackButton } from "../../contexts/BackButton";
import { useTranslation } from "../../contexts/TranslationContext";

const Games = () => {
	const { language } = useTelegram();
	const { selectedProviders } = useProviders();
	const [title, setTitle] = useState("");
	const location = useLocation();
	const { t } = useTranslation();
	const { type } = useParams();
	const { navItems } = useNavTabs();
	const [activeNav, setActiveNav] = useState(null);
	const isNewOrPopular = type === GameTypes.NEW || type === GameTypes.POPULAR;
	const navigate = useNavigate();
	const { addAction, removeAction } = useBackButton();

	useEffect(() => {
		const action = () => navigate("/");
		addAction(action);
		return () => removeAction(action);
	}, []);

	useEffect(() => {
		const newActiveNav = navItems.find((item) => item.to === `${location.pathname + location.search}`) || null;
		setActiveNav(newActiveNav);
		if (type === GameTypes.POPULAR) {
			setTitle(t("popular"));
		} else if (type === GameTypes.NEW) {
			setTitle(t("new"));
		} else {
			setTitle(newActiveNav?.label[language] || newActiveNav?.label[Language.en]);
		}
	}, [navItems, location]);

	return (
		<GamesContainer>
			{!isNewOrPopular && <TabsNavigation />}

			<div style={{ paddingTop: !isNewOrPopular ? 0 : "16px" }}>
				{type !== GameTypes.NAVIGATION && <ProvidersSection />}
			</div>

			<SectionTitle>{title}</SectionTitle>

			<GamesSection
				gameType={type || null}
				providers={type == GameTypes.NAVIGATION ? activeNav?.provider : selectedProviders}
				category={activeNav?.category}
			/>

			<Footer />
		</GamesContainer>
	);
};

export default Games;
