import { testIsIOS, testIsMobile } from "../utils/helpers/testIsMobile";

export const sizes = {
	padding: "16px",
	mainContainer: "750px",
};

export const colors = {
	blue: "#3B64F8",
	white: "#ffffff",
	lightGray: "rgba(255, 255, 255, 0.6)",
	darkGray: "rgba(255, 255, 255, 0.06)",
	darkGrayActive: "rgb(46,46,66)",
	bg: "linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%), linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%)",
};

export const fullScreenAreaHeight = testIsIOS ? "56px" : "40px";
export const fullScreenMargin = testIsIOS ? "36px" : "35px";
