import styled, { css, keyframes } from "styled-components";
import { colors, fullScreenAreaHeight, fullScreenMargin } from "./Variables.style";

export const SectionTitle = styled.h2`
	padding: ${({ padding }) => (padding ? padding : "16px 16px 0")};
	font-size: 28px;
	font-weight: 800;
	line-height: 36px;
	text-align: left;
	display: flex;
	justify-content: space-between;
`;

export const FullScreenArea = styled.div`
	position: sticky;
	top: 0;
	width: 100%;
	height: ${fullScreenAreaHeight};
`;

const skeletonKeyframes = keyframes`
	0% {
		background-color: ${colors.darkGray};
	}
	50% {
		background-color: transparent;
	}
	100% {
		background-color: ${colors.darkGray};
	}
`;

export const skeletonAnimation = css`
	animation: ${skeletonKeyframes} 1.5s infinite;
`;

export const InputStyles = css`
	padding: 0 16px;
	font-size: 14px;
	height: 40px;
	display: flex;
	align-items: center;
	font-weight: 500;
	line-height: 18px;
	width: 100%;
	border: 1px solid #414854;
	border-radius: 14px;
	background-color: ${colors.darkGray};
	color: white;

	&:hover {
		border-color: rgba(255, 255, 255, 0.3);
	}
`;

export const Icon = styled.img`
	width: 20px;
	height: 20px;
`;

export const RetryButton = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 12px;
	background-color: ${colors.white};
	color: rgba(13, 16, 22, 1);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: 0 0 5px rgba(59, 100, 248, 0.3);
	white-space: nowrap;
	box-shadow: 0 2px 0 0 ${colors.blue};
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.05);
	}
`;

export const IframeContainer = styled.div`
	position: fixed;
	top: ${({ isFullScreenMargin }) => (isFullScreenMargin ? `calc(${fullScreenAreaHeight} + ${fullScreenMargin})` : 0)};
	left: 0;
	width: 100vw;
	height: ${({ isFullScreenMargin }) => (isFullScreenMargin ? `calc(100vh - (${fullScreenAreaHeight} + ${fullScreenMargin}))` : "100vh")};
	background-color: ${({ bgColor }) => bgColor || "white"};
	z-index: 9999;

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
`;

// Анимация появления оверлея
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Анимация исчезновения оверлея
const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(13, 16, 22, 0.8);
	z-index: 100;
	transition: opacity 0.3s ease, visibility 0.3s ease;

	opacity: ${({ isShow }) => (isShow ? 1 : 0)};
	visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
	animation: ${({ isShow }) => (isShow ? fadeIn : fadeOut)} 0.3s ease;
`;
