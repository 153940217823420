import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Для навигации
import Banner2 from "./../../assets/Banner2.webp";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { SliderImage, SliderLoading, SliderWrapper, StyledSwiper } from "./MainSlider.style";
import trackMixpanel from "../../utils/trackEvent";
import { useSliderQuery } from "../../hooks/useQuery/useSliderQuery";

const MainSlider = () => {
	const navigate = useNavigate();
	const [sortedSlides, setSortedSlides] = useState([]);
	const [autoplayDelay, setAutoplayDelay] = useState(sortedSlides[0]?.delay || 5);
	const [imageLoaded, setImageLoaded] = useState({}); // Объект для отслеживания загрузки изображений для каждого слайда

	const { data: slider, isLoading, isError } = useSliderQuery();

	useEffect(() => {
		if (slider?.slides?.length) {
			let slides = [...slider.slides].filter((slide) => slide.is_active).sort((a, b) => a.position - b.position);
			setSortedSlides(slides);
		}
	}, [slider]);

	// Обработчик для изменения состояния загрузки изображения
	const handleImageLoad = (title) => {
		setImageLoaded((prevState) => ({
			...prevState,
			[title]: true, // Устанавливаем в true, что изображение с этим title загружено
		}));
	};

	const handleSlideClick = (slide) => {
		trackMixpanel("Main banner", { name: `${slider.name}_${slide.title}` });
		navigate(slide.link);
	};

	return (
		<SliderWrapper>
			{isLoading ? (
				<SliderLoading />
			) : isError ? (
				<SliderImage
					src={Banner2}
					alt="Banner"
				/>
			) : (
				<StyledSwiper>
					<Swiper
						slidesPerView={1}
						pagination={{ clickable: true }}
						modules={[Pagination, Autoplay]}
						loop={true}
						autoplay={{
							delay: autoplayDelay * 1000, // Преобразуем в миллисекунды
							disableOnInteraction: false,
						}}
						onSlideChange={(swiper) => {
							const newIndex = swiper.realIndex;
							setAutoplayDelay(sortedSlides[newIndex]?.delay || 5);
						}}
					>
						{sortedSlides.map((slide) => (
							<SwiperSlide
								key={slide.title} // Используем title как ключ
								onClick={() => handleSlideClick(slide)}
							>
								{/* Показываем индикатор загрузки, если изображение не загружено */}
								{!imageLoaded[slide.title] && <SliderLoading />}
								<SliderImage
									src={process.env.REACT_APP_BACKEND_URL + slide.image_url}
									alt={slide.title}
									onLoad={() => handleImageLoad(slide.title)} // Устанавливаем обработчик для каждого слайда
									onError={() => handleImageLoad(slide.title)} // Устанавливаем обработчик на случай ошибки загрузки
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</StyledSwiper>
			)}
		</SliderWrapper>
	);
};

export default MainSlider;
