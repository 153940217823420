import React, { useCallback, useState, useRef } from "react";
import {
	AmountOptions,
	ButtonContainer,
	ButtonTonText,
	Icon,
	InfoLabel,
	InputContainer,
	TopLabel,
	WalletOptionRow,
	WalletOptionWrap,
} from "../Wallet.styles";
import Input from "../../Input/Input";
import { useTranslation } from "../../../contexts/TranslationContext";
import { testDecimal } from "../../../utils/regexTests";
import { useWalletInfo } from "../../../contexts/WalletInfoContext";
import { useTonAddress, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { sendTransaction } from "../../../services/tonServices";
import { StatusModalType } from "../Wallet";
import { verifyTransaction } from "../../../services/userService";
import trackMixpanel from "../../../utils/trackEvent";
import BalancesSection from "../BalancesSection/BalancesSection";
import { Currency } from "../../../constants/Currency";
import CurrencyIcon from "../CurrencyIcon/CurrencyIcon";
import ArrowBottom from "../../../assets/arrow-bottom.svg";
import { isTelegramWallet } from "../../../utils/helpers/shortenAddress";

const DepositTabContent = ({ currencyKey, openCurrencyManage, showModal, handleConnectWallet }) => {
	const [amount, setAmount] = useState("");
	const { t } = useTranslation();
	const { fetchBalance, calcMinDeposit, courses, hasMinDeposit, fetchWalletBalances } = useWalletInfo();
	const [tonConnectUI] = useTonConnectUI();
	const wallet = useTonWallet();
	const walletAddress = useTonAddress();
	const [isInputError, setIsInputError] = useState(false);

	const handleSendTransaction = useCallback(async () => {
		if (!walletAddress) return await handleConnectWallet();
		if (amount < calcMinDeposit(currencyKey)) {
			setIsInputError(true);
			return;
		}
		try {
			const transaction = await sendTransaction(walletAddress, amount, "Пополнение баланса", currencyKey);
			if (!transaction?.messages) throw new Error("Некорректная структура транзакции");

			const result = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: transaction.messages.map(({ to, value, payload }) => ({
					address: to,
					amount: value,
					payload,
					bounce: true,
				})),
				keepOpened: true,
			});

			showModal(StatusModalType.loading);
			await verifyTransaction(result.boc, walletAddress, amount, "Deposit", currencyKey);
			showModal(StatusModalType.success);
			trackMixpanel("Deposit successful", { amount: `${amount} ${currencyKey}`, wallet_name: wallet?.appName });
		} catch (error) {
			showModal(StatusModalType.error);
			trackMixpanel("Deposit error", { error: error.message, wallet_name: wallet?.appName });
		} finally {
			await fetchBalance();
			await fetchWalletBalances();
		}
	}, [walletAddress, amount, calcMinDeposit, tonConnectUI, fetchBalance, handleConnectWallet]);

	const handleAmountInput = (inputValue, setValue) => {
		if (testDecimal(inputValue)) {
			setIsInputError(false);
			setValue(inputValue);
		}
	};

	return (
		<>
			{wallet && (
				<BalancesSection
					currencyKey={currencyKey}
					handleSendTransaction={handleSendTransaction}
				/>
			)}
			<WalletOptionWrap>
				<TopLabel>{t("wallet.currency")}</TopLabel>

				<WalletOptionRow onClick={openCurrencyManage}>
					<CurrencyIcon
						currencyVal={Currency[currencyKey]}
						withName
					/>
					<Icon
						src={ArrowBottom}
						alt=">"
					/>
				</WalletOptionRow>
				{courses[Currency[currencyKey]] && (
					<InfoLabel>
						{courses[Currency[currencyKey]]} {currencyKey} = 1 USDT
					</InfoLabel>
				)}
			</WalletOptionWrap>
			<InputContainer>
				<TopLabel>{t("wallet.depositAmount")}</TopLabel>
				<Input
					type="number"
					isError={isInputError}
					isFocus={isInputError}
					value={amount}
					onChange={(e) => handleAmountInput(e.target.value, setAmount)}
					placeholder={"0.00"}
				/>
				<InfoLabel>
					{t("wallet.minAmount", calcMinDeposit(currencyKey))} {currencyKey}
				</InfoLabel>
				<AmountOptions>
					{[50, 100, 150, 200, 1500].map((option) => (
						<button
							key={option}
							onClick={() => setAmount(option)}
						>
							{option}
						</button>
					))}
				</AmountOptions>
			</InputContainer>
			<ButtonContainer>
				<ButtonTonText onClick={handleSendTransaction}>
					{isTelegramWallet(wallet?.appName) && !hasMinDeposit ? t("wallet.depositTonSpace") : t("wallet.doDeposit")}
				</ButtonTonText>
			</ButtonContainer>
		</>
	);
};

export default DepositTabContent;
