import { isTMA, viewport } from "@telegram-apps/sdk";
import React, { createContext, useContext, useState, useEffect } from "react";
import { testIsMobile } from "../utils/helpers/testIsMobile";

const FullScreenContext = createContext();

export const FullScreenProvider = ({ children }) => {
	const [isFullScreen, setSetIsFullScreen] = useState(null);

	const toggleFullScreen = () => {
		setSetIsFullScreen(!isFullScreen);
		// localStorage.setItem("isFullScreen", !isFullScreen);
	};

	useEffect(() => {
		setSetIsFullScreen(testIsMobile);
	}, []);

	useEffect(() => {
		if (!isTMA("simple") || !viewport.isMounted() || isFullScreen === null) return;

		if (isFullScreen && !viewport.isFullscreen()) {
			viewport.requestFullscreen();
		} else if (!isFullScreen && viewport.isFullscreen()) {
			viewport.exitFullscreen();
		}
	}, [isFullScreen]);

	return (
		<FullScreenContext.Provider
			value={{
				isFullScreen,
				toggleFullScreen,
			}}
		>
			{children}
		</FullScreenContext.Provider>
	);
};

export const useFullScreen = () => useContext(FullScreenContext);
