import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Main from "./pages/Main/Main";
import BalanceBar from "./components/BalanceBar/BalanceBar";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import Tournaments from "./pages/Tournaments/Tournaments";
import { backButton, defineEventHandlers, on } from "@telegram-apps/sdk";
import trackMixpanel from "./utils/trackEvent";
import Games from "./pages/Games/Games";
import { ProvidersProvider } from "./contexts/ProvidersContext";
import { GlobalStyles } from "./styles/GlobalStyles.style";
import { AppContainer, AppContainerWrap } from "./styles/Containers.style";
import { TabsProvider, useNavTabs } from "./contexts/TabsNavigationContext";
import Drawer from "./components/Drawer/Drawer";
import { GameProvider, useGame } from "./contexts/GameContext";
import Game from "./components/Game/Game";
import { useModalState } from "./hooks/useModalState";
import SearchGames from "./components/Search/Search";
import useTelegram from "./hooks/useTelegram";
import Wallet from "./components/Wallet/Wallet";
import { WalletInfoProvider, useWalletInfo } from "./contexts/WalletInfoContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BlockScreen from "./components/BlockScreen/BlockScreen";
import { TranslationProvider, useTranslation } from "./contexts/TranslationContext";
import Loader from "./components/Loader/Loader";
import { sendUtmMark } from "./services/userService";
import { BackButtonProvider } from "./contexts/BackButton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FullScreenArea } from "./styles/Shared.style";
import { FullScreenProvider, useFullScreen } from "./contexts/FullScreenContext";
import PreviewWrap from "./components/PreviewWrap/PreviewWrap";
import GamePage from "./pages/GamePage/GamePage";
import { KeyboardIosProvider } from "./contexts/KeyboardIosContext";

const queryClient = new QueryClient();

const AppContentWithRouter = () => {
	const appContainerRef = useRef(null);
	const { isOpen: isGameOpen, closeGame } = useGame();
	const {
		render: renderSearch,
		active: searchActive,
		openModal: openSearch,
		closeModal: closeSearch,
	} = useModalState();
	const { isMobile } = useTelegram();
	const { t, isLoading: isTranslationLoading } = useTranslation();
	const { navItems } = useNavTabs();
	const { isAllLoading: isWalletInfoLoading, isWalletOpen, closeWallet, openWallet } = useWalletInfo();
	const location = useLocation();
	const { isFullScreen } = useFullScreen();

	useEffect(() => {
		sendUtmMark();

		trackMixpanel("Session Started");

		defineEventHandlers();
	}, []);

	useEffect(() => {
		const modalNameUrl = new URLSearchParams(location.search).get("modal");
		switch (modalNameUrl) {
			case "wallet":
				openWallet();
				break;
			default:
				break;
		}
	}, [location.search]);

	useEffect(() => {
		if (appContainerRef.current) {
			appContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, [location]);

	if (!navItems.length || isTranslationLoading || isWalletInfoLoading) return <Loader />;

	return (
		<>
			{isMobile ? (
				<AppContainerWrap>
					<AppContainer
						ref={appContainerRef}
						isFullScreenMargin={isFullScreen ? location.pathname === "/" : true}
					>
						{isFullScreen && <FullScreenArea />}
						<Routes>
							<Route
								path="/"
								element={<Main />}
							/>
							<Route
								path="/game/:gameId"
								element={<GamePage />}
							/>
							<Route
								path="/games/:type"
								element={<Games />}
							/>
							<Route
								path="/tournaments"
								element={<Tournaments />}
							/>
							<Route
								path="*"
								element={<Navigate to="/" />}
							/>
						</Routes>
						<BalanceBar openSearch={openSearch} />
						{isGameOpen && (
							<PreviewWrap
								onClose={closeGame}
								zIndex={102}
							>
								<Game />
							</PreviewWrap>
						)}
						{renderSearch && (
							<Drawer
								isOpen={searchActive}
								onClose={closeSearch}
								title={t("search.searchGames")}
							>
								<SearchGames />
							</Drawer>
						)}
						{isWalletOpen && (
							<PreviewWrap onClose={closeWallet}>
								<Wallet />
							</PreviewWrap>
						)}
					</AppContainer>
				</AppContainerWrap>
			) : (
				<BlockScreen />
			)}
		</>
	);
};

const App = () => {
	return (
		<TonConnectUIProvider
			manifestUrl="https://betongame.club/tonconnect-manifest.json"
			enableAndroidBackHandler={false}
			uiPreferences={{
				logLevel: "silent",
				theme: THEME.DARK,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<TranslationProvider>
					<ProvidersProvider>
						<TabsProvider>
							<GameProvider>
								<WalletInfoProvider>
									<BackButtonProvider>
										<FullScreenProvider>
											<KeyboardIosProvider>
												<Router>
													<AppContentWithRouter />
												</Router>
											</KeyboardIosProvider>
										</FullScreenProvider>
									</BackButtonProvider>
								</WalletInfoProvider>
							</GameProvider>
						</TabsProvider>
					</ProvidersProvider>
				</TranslationProvider>
			</QueryClientProvider>
			<GlobalStyles />
		</TonConnectUIProvider>
	);
};

export default App;
