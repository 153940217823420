import GameTypes from "../constants/GameTypes";
import { gamesOnPageLimit } from "../constants/Settings";
import { fetchWithAuth } from "./userService";

export const getGames = async (type, providers, category, page = 1, limit, signal) => {
	const params = new URLSearchParams();
	limit = limit || gamesOnPageLimit;

	if (providers) {
		if (Array.isArray(providers)) {
			const providersString = providers.join(",");
			params.append("provider", providersString);
		} else {
			params.append("provider", providers);
		}
	}

	if (category && type === GameTypes.NAVIGATION) {
		params.append("category", category);
	}

	params.append("page", page);
	params.append("limit", limit);

	const queryString = params.toString();
	const url = `/api/users/games/${type}${queryString ? `?${queryString}` : ""}`;

	return await fetchWithAuth(url, { signal });
};

export const startBgamingGame = async (gameId, language, isDemo) => {
	const url = isDemo ? "/demo" : "/sessions";
	const data = await fetchWithAuth(url, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ gameIdentifier: gameId, language }),
	});

	return data.gameUrl;
};

export const startZephyrGame = async (gameId, isDemo) => {
	const data = await fetchWithAuth(`/game/${gameId}?demo=${isDemo}`, {});
	return data.url;
};

export const startInOutGame = async (gameId, language) => {
	return await fetchWithAuth(`/inout/start-game?gameMode=${gameId}&lang=${language}`, {});
};

export const getRandomGame = async () => {
	return await fetchWithAuth(`/api/users/randomGame`, {});
};

export const searchGames = async (query, signal) => {
	return await fetchWithAuth(`/api/users/searchGames?search=${query}`, { signal });
};

export const addToFavorites = async (gameId) => {
	return await fetchWithAuth(`/api/users/favorites`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ gameId }),
	});
};

export const removeFromFavorites = async (gameId) => {
	return await fetchWithAuth(`/api/users/favorites/${gameId}`, {
		method: "DELETE",
	});
};

export const getGameByGameId = async (gameId) => {
	return await fetchWithAuth(`/api/users/game-by-id/${gameId}`, {});
};
