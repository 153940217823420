import styled from "styled-components";
import { colors } from "../../styles/Variables.style";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	gap: 32px;
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	text-align: center;
`;

export const Title = styled.h2`
	font-weight: 800;
	font-size: 22px;
	line-height: 30px;
	text-align: center;
`;

export const Text = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: ${colors.lightGray};
`;

export const WalletButton = styled.button`
	padding: 14px 16px;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;

	background-color: ${colors.white};
	color: #000000;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
	box-shadow: 0 3px 0 0 rgba(59, 248, 112, 1), 0vh 0.5vh 1vh 0.5vh rgba(59, 248, 112, 0.5);

	transition: background-color 0.3s ease, box-shadow 0.3s ease;
`;
