import { useQuery } from "@tanstack/react-query";
import { getActiveSlider } from "../../services/userService";

export const useSliderQuery = () => {
	return useQuery({
		queryKey: ["slider"],
		queryFn: () => getActiveSlider(),
		staleTime: 1000 * 60 * 5, // Данные актуальны в течение 5 минут
		gcTime: 1000 * 60 * 10, // Кэш удаляется через 10 минут
		retry: false,
	});
};
