import { useQueryClient } from "@tanstack/react-query";
import {
	addToFavorites,
	removeFromFavorites,
	startBgamingGame,
	startInOutGame,
	startZephyrGame,
} from "../../services/gamesService";
import Providers from "../../constants/Providers";
import GameTypes from "../../constants/GameTypes";

export const useGames = () => {
	const queryClient = useQueryClient();

	// Функция для старта игры
	const startGame = async (provider, gameId, isDemo, language) => {
		const gameProviderMap = {
			[Providers.BGAMING]: () => startBgamingGame(gameId, language, isDemo),
			[Providers.ZEPHYR]: () => startZephyrGame(gameId, isDemo),
			[Providers.InOut]: () => startInOutGame(gameId, language),
		};

		// Выбираем соответствующую функцию в зависимости от провайдера
		const startGameFn = gameProviderMap[provider];
		if (startGameFn) {
			let gameUrl = await startGameFn();
			resetGamesCache(GameTypes.HISTORY);
			return gameUrl;
		}
	};

	// Переключение статуса избранного
	const toggleFavorite = async ({ game_id, is_favourite }) => {
		const newFavoriteStatus = !is_favourite;

		try {
			if (newFavoriteStatus) {
				await addToFavorites(game_id);
			} else {
				await removeFromFavorites(game_id);
			}

			// Обновляем кэш
			queryClient.setQueriesData({ queryKey: ["games"], exact: false }, (cachedData) =>
				updateFavouriteGameInCache(cachedData, game_id, newFavoriteStatus),
			);

			resetGamesCache(GameTypes.FAVOURITE);
			// Возвращаем новый статус избранного
			return newFavoriteStatus;
		} catch (error) {
			console.error("Ошибка при изменении статуса избранного:", error);
			return is_favourite; // В случае ошибки возвращаем старый статус
		}
	};

	// Функция для сброса только для конкретного типа
	const resetGamesCache = (gameType) => {
		queryClient.resetQueries({ queryKey: ["games", gameType] });
	};

	return { startGame, toggleFavorite };
};

// Обновление статуса избранного в кэше
const updateFavouriteGameInCache = (cachedData, gameId, newFavoriteStatus) => {
	if (!cachedData) return cachedData;

	// Обновляем данные для InfiniteQuery
	if (cachedData.pages) {
		const updatedPages = cachedData.pages.map((page) => ({
			...page,
			data: page.data.map((game) => (game.game_id === gameId ? { ...game, is_favourite: newFavoriteStatus } : game)),
		}));
		return { ...cachedData, pages: updatedPages };
	}

	// Обновляем данные для обычного Query
	if (cachedData.data) {
		const updatedGames = cachedData.data.map((game) =>
			game.game_id === gameId ? { ...game, is_favourite: newFavoriteStatus } : game,
		);
		return { ...cachedData, data: updatedGames };
	}

	return cachedData;
};
