import React, { useEffect } from "react";
import { DrawerMiniContent, DrawerMiniEl, DrawerMiniTitle, DrawerMiniTitleWrap } from "./DrawerMini.style";
import { useBackButton } from "../../../contexts/BackButton";
import { Overlay } from "../../../styles/Shared.style";

const DrawerMini = ({ isOpen, onClose, title, children }) => {
	const { addAction, removeAction } = useBackButton();

	useEffect(() => {
		addAction(onClose);
		return () => removeAction(onClose);
	}, []);

	return (
		<>
			<Overlay
				isShow={isOpen}
				onClick={() => onClose()}
			/>
			<DrawerMiniEl isOpen={isOpen}>
				<DrawerMiniContent>
					<DrawerMiniTitleWrap>
						<DrawerMiniTitle>{title}</DrawerMiniTitle>
					</DrawerMiniTitleWrap>
					{children}
				</DrawerMiniContent>
			</DrawerMiniEl>
		</>
	);
};

export default DrawerMini;
