import React, { useState, useEffect, useLayoutEffect } from "react";
import {
	GameContainer,
	GameImage,
	GameTitle,
	GameProvider,
	ButtonContainer,
	DemoButton,
	PlayButton,
	ErrorMessage,
	LikeButton,
	LikeIcon,
	GameTitleContainer,
	GameImageWrap,
	GameFrameWrap,
} from "./Game.style";
import Loader from "../../components/Loader/Loader";
import trackMixpanel from "../../utils/trackEvent";
import { useTranslation } from "../../contexts/TranslationContext";
import useTelegram from "../../hooks/useTelegram";
import { IframeContainer } from "../../styles/Shared.style";
import { useWalletInfo } from "../../contexts/WalletInfoContext";
import getGameImage from "../../utils/helpers/getGameImage";
import { useGame } from "../../contexts/GameContext";
import { useGames } from "../../hooks/useQuery/useGames";
import { useFullScreen } from "../../contexts/FullScreenContext";

const Game = () => {
	const [gameUrl, setGameUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [startError, setStartError] = useState("");
	const { game, setGame } = useGame();
	const { language } = useTelegram();
	const { t } = useTranslation();
	const { fetchBalance } = useWalletInfo();
	const { isFullScreen } = useFullScreen();
	const { toggleFavorite, startGame } = useGames();

	useEffect(() => {
		// Обновляем баланс при выходе из игры
		return () => {
			if (gameUrl) {
				fetchBalance();
			}
		};
	}, [gameUrl]);

	useLayoutEffect(() => {
		//Сразу запускаем игры с demo_mode = false
		if (!game?.demo_mode) {
			handleStartGame(false);
		}
	}, []);

	const handleStartGame = async (isDemo) => {
		let gameId = game?.game_id;
		setIsLoading(true);
		try {
			let gameUrl = await startGame(game?.provider, gameId, isDemo, language);

			setGameUrl(gameUrl);
			trackMixpanel(isDemo ? "Demo launch" : "Game launch", { game_name: game?.name });
		} catch (error) {
			setStartError(t("game.errorStart"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleLike = async () => {
		const newStatus = await toggleFavorite(game);
		setGame((prev) => ({ ...prev, is_favourite: newStatus }));
	};

	return (
		<GameContainer>
			{isLoading ? (
				<Loader overlayBg={"black"} />
			) : gameUrl ? (
				<GameFrameWrap>
					<IframeContainer
						isFullScreenMargin={isFullScreen}
						bgColor="black"
					>
						<iframe
							src={gameUrl}
							title="Game"
							allowFullScreen
						/>
					</IframeContainer>
				</GameFrameWrap>
			) : (
				<>
					<GameTitleContainer>
						<div>
							<GameTitle>{game?.name}</GameTitle>
							<GameProvider>{game?.provider}</GameProvider>
						</div>
						<LikeButton onClick={handleLike}>
							<LikeIcon isLiked={game?.is_favourite} />
						</LikeButton>
					</GameTitleContainer>

					<GameImageWrap>
						<GameImage
							src={`${getGameImage(game)}`}
							alt={game?.name}
						/>
					</GameImageWrap>

					{startError && <ErrorMessage>{startError}</ErrorMessage>}

					<ButtonContainer>
						{game?.demo_mode && <DemoButton onClick={() => handleStartGame(true)}>{t("demoMode")}</DemoButton>}
						<PlayButton onClick={() => handleStartGame(false)}>{t("play")}</PlayButton>
					</ButtonContainer>
				</>
			)}
		</GameContainer>
	);
};

export default Game;
