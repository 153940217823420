import styled from "styled-components";
import { colors, fullScreenAreaHeight, fullScreenMargin } from "../../styles/Variables.style";

const fullScreenHeight = `calc(${fullScreenAreaHeight} + ${fullScreenMargin})`;

export const Wrapper = styled.div`
	position: fixed;
	background: ${colors.bg};
	top: 0;
	left: 0;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	right: 0;
	bottom: 0;
	z-index: ${({ zIndex }) => zIndex || 101};
`;

export const Content = styled.div`
	overflow: auto;
	height: 100vh;
	padding-top: ${({ isFullScreenMargin }) => (isFullScreenMargin ? fullScreenHeight : "0px")};
`;

