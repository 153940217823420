import React, { useEffect, useState } from "react";
import {
	FooterContainer,
	FooterLogo,
	SupportButtons,
	SupportButton,
	SupportIcon,
	FooterText,
	ArrowIcon,
	DocumentLinks,
	DocumentLink,
	ButtonTitle,
	WarnImage,
	SupportButtonContent,
	ButtonDecorLine,
} from "./Footer.styles";
import MessagesIcon from "../../assets/Footer/MessagesIcon.svg";
import LogoIcon from "../../assets/Footer/LogoIcon.svg";
import BotIcon from "../../assets/Footer/BotIcon.svg";
import ArrowRight from "../../assets/arrow-right.svg";
import UrlIcon from "../../assets/urlImg.svg";
import { useTranslation } from "../../contexts/TranslationContext";
import { useModalState } from "../../hooks/useModalState";
import Drawer from "../Drawer/Drawer";
import { Icon, IframeContainer } from "../../styles/Shared.style";
import WarnIcon from "../../assets/18plus.webp";

const Footer = () => {
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();

	const { render, active, openModal, closeModal } = useModalState();
	const [curLink, setCurLink] = useState("");

	const handleClickLink = (link) => {
		setCurLink(link);
		openModal();
	};

	const links = [
		{
			name: t("footer.termsAndConditions"),
			link: "https://docs.google.com/document/d/11ekQ9BercG2tbAtzdnvMab4R7chnDfV2Ce_bZ_39X0A/edit?usp=sharing",
		},
		{
			name: t("footer.privacyPolicy"),
			link: "https://docs.google.com/document/d/1DROfp160i8qQ2PBHTjduJro4-BdG8viPHsP1bcTcofE/edit?usp=sharing",
		},
		{
			name: t("footer.underagePolicy"),
			link: "https://docs.google.com/document/d/1r_D_RIoomVmQIhqTKluooGbHmfUuJrHlWnB-rMXCtws/edit?usp=sharing",
		},
		{
			name: t("footer.responsibleGambling"),
			link: "https://docs.google.com/document/d/1ynAH64o1NXVHDndwzKxMwe58lA5XmCqrmysABKEQwjA/edit?usp=sharing",
		},
	];

	const buttons = [
		{
			title: t("footer.support"),
			note: t("footer.supportNote"),
			icon: MessagesIcon,
			color: "rgba(59, 248, 112, 1)",
			link: "https://t.me/BeTonSupportBot",
		},
		{
			title: t("footer.channel"),
			note: t("footer.channelNote"),
			icon: LogoIcon,
			color: "linear-gradient(233.68deg, #FE9809 12.3%, #FE5922 159.43%)",
			link: "https://t.me/beton_casino_official",
		},
		{
			title: t("footer.bot"),
			note: t("footer.botNote"),
			icon: BotIcon,
			color: "rgba(59, 100, 248, 1)",
			link: "https://t.me/betonproject_bot",
		},
	];

	return (
		<FooterContainer>
			<SupportButtons>
				{buttons.map((button, index) => {
					return (
						<SupportButton
							onClick={() => window.open(button.link)}
							key={index}
							decorBackground={button.color}
						>
							<ButtonDecorLine background={button.color} />
							<SupportButtonContent>
								<SupportIcon src={button.icon} />
								<ButtonTitle>
									<h3>{button.title}</h3>
									<span>{button.note}</span>
								</ButtonTitle>
							</SupportButtonContent>
							<Icon src={ArrowRight} />
						</SupportButton>
					);
				})}
			</SupportButtons>

			<DocumentLinks>
				{links.map((item, index) => {
					return (
						<DocumentLink
							key={index}
							onClick={() => handleClickLink(item.link)}
						>
							{item.name}
							<ArrowIcon
								src={UrlIcon}
								alt="Arrow"
							/>
						</DocumentLink>
					);
				})}
			</DocumentLinks>

			<WarnImage src={WarnIcon} />

			<FooterText>{t("footer.copyright", currentYear)}</FooterText>

			{render && (
				<Drawer
					isOpen={active}
					onClose={closeModal}
					isFullScreenMargin={false}
				>
					<IframeContainer>
						<iframe
							src={curLink}
							title="Docs"
							allowFullScreen
						/>
					</IframeContainer>
				</Drawer>
			)}
		</FooterContainer>
	);
};

export default Footer;
