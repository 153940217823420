import React, { useEffect, useRef } from "react";
import { ClearIcon, InputEl, InputWrap } from "./Input.style";
import CloseWhite from "../../assets/close-white.svg";
import { useKeyboardIos } from "../../contexts/KeyboardIosContext";

const Input = ({ type, value, isError, onChange, placeholder, onFocus, onBlur, isFocus }) => {
	const inputRef = useRef(null);
	const { keyboardOpen, keyboardClose } = useKeyboardIos();

	const handleFocus = () => {
		onFocus?.();
		keyboardOpen();
		scrollToInput();
	};

	const handleBlur = () => {
		onBlur?.();
		keyboardClose();
	};

	const scrollToInput = () => {
		setTimeout(() => {
			inputRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		}, 300);
	};

	useEffect(() => {
		if (isFocus) {
			inputRef.current.focus();
		}
	}, [isFocus]);

	const handleClear = () => {
		onChange({ target: { value: "" } });
	};

	return (
		<InputWrap>
			<InputEl
				ref={inputRef}
				type={type}
				isError={isError}
				value={value}
				onChange={onChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				placeholder={placeholder}
			/>
			{value && (
				<ClearIcon
					onClick={handleClear}
					src={CloseWhite}
					alt="clear"
				/>
			)}
		</InputWrap>
	);
};

export default Input;
