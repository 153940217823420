import React from "react";
import { ModalContainer, ModalContent } from "./Modal.style";
import { Overlay } from "../../styles/Shared.style";

const Modal = ({ isShow, onClose, children }) => {
	return (
		<>
			<Overlay
				isShow={isShow}
				onClick={onClose}
			/>
			<ModalContainer isShow={isShow}>
				<ModalContent>{children}</ModalContent>
			</ModalContainer>
		</>
	);
};

export default Modal;
