import React, { createContext, useContext, useState } from "react";
import { testIsIOS } from "../utils/helpers/testIsMobile";

const KeyboardIosContext = createContext();

export const KeyboardIosProvider = ({ children }) => {
    const [keyboardIosHeight, setKeyboardIosHeight] = useState(0);

    const keyboardOpen = () => {
        if(!testIsIOS) return;
        setKeyboardIosHeight(300);
    };

    const keyboardClose = () => {
        if(!testIsIOS) return;
        setKeyboardIosHeight(0);
    };

	return (
		<KeyboardIosContext.Provider value={{ keyboardIosHeight, keyboardOpen, keyboardClose }}>
			{children}
		</KeyboardIosContext.Provider>
	);
};

// Хук для использования контекста
export const useKeyboardIos = () => useContext(KeyboardIosContext);
