import Providers from "../../constants/Providers";

const providerImageMap = {
	[Providers.InOut]: (src) => src,
	default: (src) => `${process.env.REACT_APP_BACKEND_URL}${src}`,
};

export default function getGameImage(game) {
	if (!game?.src) return null;

	const getImageUrl = providerImageMap[game.provider] || providerImageMap.default;
	return getImageUrl(game.src);
}
