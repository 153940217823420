import React, { useEffect } from "react";
import { Container, Title } from "./Tournaments.style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationContext";
import { useBackButton } from "../../contexts/BackButton";
import { useFullScreen } from "../../contexts/FullScreenContext";

const Tournaments = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { addAction, removeAction } = useBackButton();
	const { isFullScreen } = useFullScreen();

	useEffect(() => {
		const action = () => navigate(-1);
		addAction(action);
		return () => {
			removeAction(action);
		};
	}, []);

	return (
		<Container isFullScreenMargin={isFullScreen}>
			<Title>{t("comingSoon")}</Title>
		</Container>
	);
};

export default Tournaments;
