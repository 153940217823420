import React, { useState, useEffect, useCallback } from "react";
import { useTonConnectUI, useTonAddress, useTonConnectModal, useTonWallet } from "@tonconnect/ui-react";
import {
	WalletContainer,
	TabsContainer,
	TabButton,
	WalletConnectText,
	InfoLabel,
	TopLabel,
	ContentWrap,
	Icon,
	WalletOptionRow,
	WalletOptionWrap,
} from "./Wallet.styles";
import { useLocation } from "react-router-dom";
import trackMixpanel from "../../utils/trackEvent";
import DrawerMini from "../../components/Drawer/DrawerMini/DrawerMini";
import WalletManage from "./WalletManage/WalletManage";
import StatusModal from "./StatusModal/StatusModal";
import ArrowBottom from "../../assets/arrow-bottom.svg";
import PlusCircle from "../../assets/Wallet/plus-circle.svg";
import { useModalState } from "../../hooks/useModalState";
import { useWalletInfo } from "../../contexts/WalletInfoContext";
import { useTranslation } from "../../contexts/TranslationContext";
import CurrencyManage from "./CurrencyManage/CurrencyManage";
import { Currency } from "../../constants/Currency";
import { shortenAddress } from "../../utils/helpers/shortenAddress";
import DepositTabContent from "./TabsContent/DepositTabContent";
import WithdrawTabContent from "./TabsContent/WithdrawTabContent";
import { useKeyboardIos } from "../../contexts/KeyboardIosContext";

const ActiveTab = {
	deposit: "deposit",
	withdraw: "withdraw",
};

export const StatusModalType = {
	success: "success",
	error: "error",
	wagering: "wagering",
	loading: "loading",
};

const Wallet = () => {
	const { balance, remainingWagering } = useWalletInfo();
	const [tonConnectUI] = useTonConnectUI();
	const walletAddress = useTonAddress();
	const wallet = useTonWallet();
	const { open, state: connectModalState } = useTonConnectModal();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(ActiveTab.deposit);
	const [modalType, setModalType] = useState(null);
	const [isShowModal, setIsShowModal] = useState(false);
	const { keyboardIosHeight } = useKeyboardIos();
	const [currencyKey, setCurrencyKey] = useState(() => {
		const storedCurrencyKey = localStorage.getItem("currencyKey");
		return storedCurrencyKey in Currency ? storedCurrencyKey : "USDT";
	});
	const { t } = useTranslation();
	const {
		render: renderWalletManage,
		active: walletManageActive,
		openModal: openWalletManage,
		closeModal: closeWalletManage,
	} = useModalState();
	const {
		render: renderCurrencyManage,
		active: currencyManageActive,
		openModal: openCurrencyManage,
		closeModal: closeCurrencyManage,
	} = useModalState();

	useEffect(() => {
		if (localStorage.getItem("hasWallet")) return;
		if (connectModalState.status === "closed" && connectModalState.closeReason === "wallet-selected") {
			trackMixpanel("Wallet connected", { balance, wallet_name: wallet?.appName });
			localStorage.setItem("hasWallet", true);
		}
	}, [connectModalState, balance]);

	useEffect(() => {
		trackMixpanel("Cashbox", { referrer: location.pathname });
	}, [location.pathname]);

	const handleConnectWallet = useCallback(() => {
		trackMixpanel("Wallet connect", { balance, wallet_name: wallet?.appName });
		tonConnectUI.setConnectRequestParameters({ state: "ready", items: [{ name: "ton_addr" }] });
		open();
	}, [tonConnectUI, balance, open]);

	const handleTabClick = useCallback(
		(tab) => {
			if (tab === ActiveTab.withdraw && remainingWagering > 0)
				return setModalType(StatusModalType.wagering), setIsShowModal(true);
			setActiveTab(tab);
		},
		[remainingWagering],
	);

	const handleSetCurrencyKey = useCallback((currencyKey) => {
		setCurrencyKey(currencyKey);
		localStorage.setItem("currencyKey", currencyKey);
		closeCurrencyManage();
	}, []);

	const showModal = (statusModalType) => {
		setModalType(statusModalType);
		setIsShowModal(true);
	};

	return (
		<>
			<WalletContainer keyboardIosHeight={keyboardIosHeight}>
				<TabsContainer>
					{Object.entries(ActiveTab).map((item) => {
						return (
							<TabButton
								key={item[1]}
								active={activeTab === item[1]}
								onClick={() => handleTabClick(item[1])}
							>
								{t(`wallet.${item[1]}`)}
							</TabButton>
						);
					})}
				</TabsContainer>

				<ContentWrap>
					<WalletOptionWrap>
						<TopLabel>{t("wallet")}</TopLabel>

						<WalletOptionRow onClick={!walletAddress ? handleConnectWallet : openWalletManage}>
							{!wallet && (
								<Icon
									src={PlusCircle}
									alt="+"
								/>
							)}
							{wallet?.imageUrl && (
								<Icon
									src={wallet.imageUrl}
									alt={""}
								/>
							)}

							<WalletConnectText>{walletAddress ? shortenAddress(walletAddress) : t("wallet.connectWallet")}</WalletConnectText>
							<Icon
								src={ArrowBottom}
								alt=">"
							/>
						</WalletOptionRow>
					</WalletOptionWrap>

					{activeTab === ActiveTab.deposit && (
						<DepositTabContent
							currencyKey={currencyKey}
							openCurrencyManage={openCurrencyManage}
							handleConnectWallet={handleConnectWallet}
							showModal={showModal}
						/>
					)}
					{activeTab === ActiveTab.withdraw && (
						<WithdrawTabContent
							handleConnectWallet={handleConnectWallet}
							showModal={showModal}
						/>
					)}
				</ContentWrap>
			</WalletContainer>

			{renderWalletManage && (
				<DrawerMini
					isOpen={walletManageActive && walletAddress}
					onClose={closeWalletManage}
					title={t("wallet")}
				>
					<WalletManage handleConnectWallet={handleConnectWallet} />
				</DrawerMini>
			)}

			{renderCurrencyManage && (
				<DrawerMini
					isOpen={currencyManageActive}
					onClose={closeCurrencyManage}
					title={t("wallet.currency")}
				>
					<CurrencyManage setCurrency={handleSetCurrencyKey} />
				</DrawerMini>
			)}

			<StatusModal
				isShow={isShowModal}
				onClose={() => setIsShowModal(null)}
				modalType={modalType}
				activeTab={activeTab}
				remainingWagering={remainingWagering}
			/>
		</>
	);
};

export default Wallet;
