import styled from "styled-components";
import { colors, fullScreenAreaHeight, fullScreenMargin } from "../../styles/Variables.style";

const fullScreenHeight = `calc(${fullScreenAreaHeight} + ${fullScreenMargin} + 16px)`;

export const DrawerEl = styled.div`
	position: fixed;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	overflow: hidden;
	will-change: transform;
	z-index: ${({ zIndex }) => zIndex || 101};
	box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);

	${({ direction = "bottom", isFullScreenMargin }) => {
		switch (direction) {
			case "top":
				return `
					top: 0; left: 0; right: 0; height: 100%;
					transform: translate3d(0, -100%, 0);
				`;
			case "left":
				return `
					top: 0; left: 0; bottom: 0; width: 100%;
					transform: translate3d(-100%, 0, 0);
				`;
			case "right":
				return `
					top: 0; right: 0; bottom: 0; width: 100%;
					transform: translate3d(100%, 0, 0);
				`;
			default:
				return `
					top: ${isFullScreenMargin ? fullScreenHeight : 0}; left: 0; right: 0; bottom: 0;
					transform: translate3d(0, 100%, 0);
				`;
		}
	}}

	${({ isOpen }) =>
		isOpen &&
		`
		transform: translate3d(0, 0, 0);
	`}
`;

export const DrawerContent = styled.div`
	width: 100vw;
	height: ${({ isFullScreenMargin }) => (isFullScreenMargin ? `calc(100vh - ${fullScreenHeight})` : `100vh`)};
	display: flex;
	border-radius: ${({ isFullScreenMargin }) => (isFullScreenMargin ? `16px 16px 0 0` : `0`)};
	flex-direction: column;
	background: ${colors.bg};
	overflow-y: auto;
`;

export const DrawerTitleWrap = styled.div`
	display: flex;
	justify-content: space-between;

	padding: ${({ isTitle }) => (isTitle ? "32px" : "16px")} 16px 0;
`;

export const DrawerTitle = styled.div`
	font-weight: 800;
	font-size: 22px;
	line-height: 30px;
`;
