import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { gamesOnPageLimit } from "../../constants/Settings";
import { getGames, searchGames } from "../../services/gamesService";

// Использование ключа для различных типов игр (history, favourite и т.д.)
export const useGamesQuery = (gameType, providers, category, page, limit) => {
	return useQuery({
		queryKey: ["games", gameType, providers, category, page, limit], // gameType теперь часть ключа
		queryFn: ({ signal }) => getGames(gameType, providers, category, page, limit, signal),
		enabled: !!gameType,
		staleTime: 1000 * 60 * 5, // Данные актуальны в течение 5 минут
		gcTime: 1000 * 60 * 10, // Кэш удаляется через 10 минут
	});
};

export const useInfiniteGamesQuery = (gameType, providers, category) => {
	return useInfiniteQuery({
		queryKey: ["games", gameType, providers, category], // gameType теперь часть ключа
		queryFn: ({ pageParam = 1, signal }) =>
			getGames(gameType, providers, category, pageParam, gamesOnPageLimit, signal),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.totalPages > allPages.length ? allPages.length + 1 : undefined;
		},
		enabled: !!gameType,
		staleTime: 1000 * 60 * 5, // Данные актуальны в течение 5 минут
		gcTime: 1000 * 60 * 10, // Кэш будет удаляться через 10 минут
	});
};

export const useSearchGamesQuery = (query) => {
	return useQuery({
		queryKey: ["searchGames", query],
		queryFn: ({ signal }) => searchGames(query, signal),
		enabled: !!query,
		retry: false,
	});
};
