import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Game from "../../components/Game/Game";
import { useBackButton } from "../../contexts/BackButton";
import { useFullScreen } from "../../contexts/FullScreenContext";
import { useGame } from "../../contexts/GameContext";
import { getGameByGameId } from "../../services/gamesService";
import Loader from "../../components/Loader/Loader";
import { Container, NotFoundContainer } from "./GamePage.style";
import { useTranslation } from "../../contexts/TranslationContext";

const GamePage = () => {
	const navigate = useNavigate();
	const { addAction, removeAction } = useBackButton();
	const { isFullScreen } = useFullScreen();
	const { gameId } = useParams();
	const { t } = useTranslation();
	const { game, setGame } = useGame();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getGame = async () => {
			if (gameId) {
				try {
					setGame(null);
					setIsLoading(true);
					const game = await getGameByGameId(gameId);
					setGame(game);
				} catch (error) {
					console.error("Error fetching game:", error);
				} finally {
					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};
		getGame();
	}, [gameId]);

	useEffect(() => {
		const action = () => navigate("/");
		addAction(action);
		return () => {
			removeAction(action);
		};
	}, []);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : game ? (
				<Container isFullScreenMode={isFullScreen}>
					<Game />
				</Container>
			) : (
				<NotFoundContainer>
					<h2>{t("game.notFound")}</h2>
				</NotFoundContainer>
			)}
		</>
	);
};

export default GamePage;
