import styled, { css } from "styled-components";
import { skeletonAnimation } from "../../styles/Shared.style";

export const SliderWrapper = styled.div`
	width: 100%;
	padding-top: calc(9 / 16 * 100%);
	position: relative;

	& > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const SliderImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	pointer-events: none;
`;

export const SliderLoading = styled.div`
	width: 100%;
	height: 100%;
	${skeletonAnimation};
`;

export const StyledSwiper = styled.div`
	.swiper {
		height: 100%;
		cursor: pointer;
	}

	.swiper-pagination {
		position: absolute;
		bottom: 8px;
		left: 16px;
		display: inline-flex;
		align-items: center;
		gap: 4px; /* Отступ между точками */
		padding: 0 6px;
		height: 12px;
		border-radius: 6px;
		width: fit-content;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
			linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
		backdrop-filter: blur(16px);
	}

	.swiper-pagination-bullet {
		background-color: rgba(255, 255, 255, 0.3);
		opacity: 1;
		width: 4px;
		height: 4px;
		border-radius: 2px;
		transition: all 0.3s ease;
		margin: 0 !important;
	}

	.swiper-pagination-bullet-active {
		background-color: white !important; /* Цвет активной точки */
		width: 28px;
	}
`;
