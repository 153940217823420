import React, { createContext, useContext, useState, useEffect } from "react";
import { useModalState } from "../hooks/useModalState";

const GameContext = createContext();

export const GameProvider = ({ children }) => {
	const [game, setGame] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const openGame = () => setIsOpen(true);
	const closeGame = () => setIsOpen(false);

	return <GameContext.Provider value={{ game, setGame, isOpen, openGame, closeGame }}>{children}</GameContext.Provider>;
};

export const useGame = () => useContext(GameContext);
