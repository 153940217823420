import React, { useCallback, useState } from "react";
import {
	ButtonContainer,
	ButtonTonText,
	InfoLabel,
	InputContainer,
	TopLabel,
} from "../Wallet.styles";
import Input from "../../Input/Input";
import { useTranslation } from "../../../contexts/TranslationContext";
import { testDecimal } from "../../../utils/regexTests";
import { StatusModalType } from "../Wallet";
import { useWalletInfo } from "../../../contexts/WalletInfoContext";
import { useTonAddress } from "@tonconnect/ui-react";
import { withdrawFunds } from "../../../services/tonServices";

const WithdrawTabContent = ({ handleConnectWallet, showModal }) => {
	const { t } = useTranslation();
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const { fetchBalance, minWithdraw, remainingWagering } = useWalletInfo();
	const wallet = useTonAddress();
	const [isInputError, setIsInputError] = useState(false);

	const handleWithdraw = useCallback(async () => {
		if (!wallet) return handleConnectWallet();
		if (withdrawAmount < minWithdraw || parseFloat(withdrawAmount) <= 0) {
			setIsInputError(true);
			return;
		}
		try {
			if (remainingWagering > 0) return showModal(StatusModalType.wagering);
			await withdrawFunds(wallet, withdrawAmount, "Withdrawal from Casino");
			showModal(StatusModalType.success);
		} catch {
			showModal(StatusModalType.error);
		} finally {
			await fetchBalance();
		}
	}, [wallet, withdrawAmount, minWithdraw, remainingWagering, handleConnectWallet]);

	const handleAmountInput = (inputValue, setValue) => {
		if (testDecimal(inputValue)) {
			setIsInputError(false);
			setValue(inputValue);
		}
	};

	return (
		<>
			<InputContainer>
				<TopLabel>{t("wallet.withdrawAmount")}</TopLabel>
				<Input
					type="number"
					isError={isInputError}
					isFocus={isInputError}
					value={withdrawAmount}
					onChange={(e) => handleAmountInput(e.target.value, setWithdrawAmount)}
					placeholder={"0.00"}
				/>
				<InfoLabel>{t("wallet.minAmount", minWithdraw)} USDT</InfoLabel>
			</InputContainer>
			<ButtonContainer>
				<ButtonTonText onClick={handleWithdraw}>{t("wallet.doWithdraw")}</ButtonTonText>
			</ButtonContainer>
		</>
	);
};

export default WithdrawTabContent;
