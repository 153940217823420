import styled, { css } from "styled-components";
import { sizes } from "../../styles/Variables.style";
import { skeletonAnimation } from "../../styles/Shared.style";

export const BalanceBarContainer = styled.div`
	position: fixed;
	margin: 0 auto;
	bottom: 0;
	max-width: ${sizes.mainContainer};
	width: 100%;
	display: flex;
	justify-content: space-between;
	background: linear-gradient(
			99.31deg,
			rgba(13, 16, 22, 0.8) -9.79%,
			rgba(17, 30, 54, 0.8) 20.84%,
			rgba(13, 16, 22, 0.8) 94.54%
		),
		linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%);
	padding: 8px 0 ${({ isFullScreenMargin }) => (isFullScreenMargin ? "30px" : "8px")};
	-webkit-backdrop-filter: blur(12px); /* Safari и старые версии Chrome */
	backdrop-filter: blur(12px); /* Современные браузеры */
	z-index: 100;
`;

const navItemStyle = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	width: 100%;
	transition: transform 0.3s ease;
`;

export const NavItem = styled.div`
	${navItemStyle};
	&:hover {
		transform: scale(1.05);
	}
`;

export const Icon = styled.img`
	width: 20px;
	height: 20px;
`;

export const NavText = styled.p`
	color: ${({ isActive }) => (isActive ? "rgba(59, 100, 248, 1)" : "white")};
	font-size: 10px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: -0.02em;
`;

export const WalletButtonContainer = styled.div`
	${navItemStyle};
	position: relative;
	justify-content: end;
	padding: 0 5px;
`;

export const BalanceAmount = styled.p`
	color: white;
	font-weight: 500;
	line-height: 14px;
	font-size: 10px;
	letter-spacing: -0.02em;
	text-align: center;
	width: 50px;
	border-radius: 6px;
	${({ isLoading }) => isLoading && skeletonAnimation};
`;

export const WalletButton = styled.div`
	display: flex;
	position: absolute;
	bottom: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-radius: 50%;
	background: linear-gradient(
			99.31deg,
			rgba(13, 16, 22, 0.8) -9.79%,
			rgba(17, 30, 54, 0.8) 20.84%,
			rgba(13, 16, 22, 0.8) 94.54%
		),
		linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.6) 5.44%, rgba(13, 16, 22, 0) 93.15%);
	box-shadow: 0 2px 0 0 rgba(59, 248, 112, 1);
`;
