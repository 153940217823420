import getInitData from "../utils/getInitData";
import { testIsMobile } from "../utils/helpers/testIsMobile";

const useTelegram = () => {
	const { initData, platform } = getInitData();
	const isMobile = testIsMobile && !["unknown", "tdesktop"].includes(platform);

	const language = navigator.language?.split("-")[0] || initData?.user?.languageCode || "en";

	return {
		language,
		user: initData?.user || {},
		isMobile: process.env.REACT_APP_ENV === "DEV" || isMobile,
	};
};

export default useTelegram;
