import styled from "styled-components";
import { fullScreenMargin, sizes } from "./Variables.style";

export const AppContainerWrap = styled.div`
	min-height: 100vh;
	height: 100vh;
	overflow-y: auto;
	max-width: ${sizes.mainContainer};
	margin: 0 auto;
	overflow-x: hidden;
`;

export const AppContainer = styled.div`
	height: 100%;
	padding-top: ${({ isFullScreenMargin }) => (isFullScreenMargin ? "0" : fullScreenMargin)};
`;

export const ScrollContainer = styled.div`
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: block;
		width: 3px; /* Ширина скроллбара */
	}

	&::-webkit-scrollbar-thumb {
		background-color: #253f90;
		border-radius: 1.5px;
	}

	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: #253f90 transparent; /* Цвет ползунка и фона трека */

	/* Internet Explorer 10+ */
	-ms-overflow-style: scrollbar;
`;

// export const MainContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   width: 100%;
//   color: white;
//   overflow: hidden;
//   padding: 0;
//   position: relative;
// `;
