import styled from "styled-components";

export const MainContainer = styled.div``;

export const GradientGrid = styled.div``;

export const GamesWrap = styled.div`
	padding: 16px;
`;

export const ViewAllBtn = styled.div`
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	line-height: 18px;
	display: flex;
	align-self: flex-end;
	display: flex;
	gap: 8px;
	padding-bottom: 3px;

	img {
		height: 20px;
		width: 20px;
	}
`;
