import styled from "styled-components";

const animation = "cubic-bezier(0.25, 0.8, 0.25, 1)";

export const DrawerMiniEl = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	overflow: hidden;
	background: #10192c;
	transform: translate3d(0, 100%, 0);
	transition: transform 0.4s ${animation};
	z-index: 101;
	border-radius: 20px 20px 0 0;
	box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);
	will-change: transform;

	${({ isOpen }) => isOpen && `transform: translate3d(0, 0, 0);`}
`;

export const DrawerMiniContent = styled.div`
	width: 100vw;
	display: flex;
	flex-direction: column;
	overflow: auto;
`;

export const DrawerMiniTitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 16px 0;
`;

export const DrawerMiniTitle = styled.div`
	font-weight: 800;
	font-size: 22px;
	line-height: 30px;
`;
