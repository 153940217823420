import React, { useState, useEffect } from "react";
import {
	ModalOverlay,
	ModalContainer,
	ModalContent,
	ModalMessage,
	ModalButton,
	Icon,
	RemainingWagerContainer,
	RemainingWagerAmount,
	RemainingWagerText,
	ModalMessageTitle,
} from "./StatusModal.style";
import Loader from "../../../components/Loader/Loader";
import SuccessIcon from "../../../assets/Wallet/successIcon.png";
import ErrorIcon from "../../../assets/Wallet/errorIcon.png";
import { useTranslation } from "../../../contexts/TranslationContext";
import { useWalletInfo } from "../../../contexts/WalletInfoContext";
import { StatusModalType } from "../Wallet";

const getModalContent = (type, activeTab, t) => {
	const isDeposit = activeTab === "deposit";

	const modalContent = {
		success: {
			icon: SuccessIcon,
			title: t("success"),
			message: isDeposit ? t("successDepositMessage") : t("successWithdrawMessage"),
			buttonText: isDeposit ? t("play") : t("close"),
		},
		error: {
			icon: ErrorIcon,
			title: t("somethingWentWrong"),
			message: isDeposit ? t("errorDepositMessage") : t("errorWithdrawMessage"),
			buttonText: t("tryAgain"),
		},
		wagering: {
			icon: null,
			title: t("blockWithdraw"),
			message: t("blockWithdrawMessage"),
			buttonText: t("play"),
		},
	};

	return modalContent[type] || null;
};

const StatusModal = ({ isShow, onClose, modalType, activeTab, remainingWagering }) => {
	const [modalContent, setModalContent] = useState(null);
	const { t } = useTranslation();
	const { closeWallet } = useWalletInfo();

	useEffect(() => {
		setModalContent(getModalContent(modalType, activeTab, t));
	}, [modalType, activeTab]);

	const handleClose = () => {
		onClose();
		if(modalType === StatusModalType.success) {
			closeWallet();
		}
	};	

	return (
		<>
			<ModalOverlay isShow={isShow}>
				{modalType === StatusModalType.loading ? (
					<Loader
						isFullScreen={false}
						overlayBg={"transparent"}
					/>
				) : (
					<ModalContainer isShow={isShow}>
						<ModalContent>
							{modalContent?.icon && <Icon src={modalContent.icon} />}
							<ModalMessage>
								<ModalMessageTitle fontSize={modalType === "wagering" ? "22px" : "28px"}>
									{modalContent?.title}
								</ModalMessageTitle>
								<p>{modalContent?.message}</p>
							</ModalMessage>

							{modalType === "wagering" && (
								<RemainingWagerContainer>
									<RemainingWagerAmount>{remainingWagering} USDT</RemainingWagerAmount>
									<RemainingWagerText>{t("remainingWagering")}</RemainingWagerText>
								</RemainingWagerContainer>
							)}

							<ModalButton onClick={handleClose}>{modalContent?.buttonText}</ModalButton>
						</ModalContent>
					</ModalContainer>
				)}
			</ModalOverlay>
		</>
	);
};

export default StatusModal;
