import React, { useState, useEffect } from "react";
import { FixedBackground, WhiteBarWithDots, GradientGrid, MainContainer, GamesWrap, ViewAllBtn } from "./Main.styles";
import Footer from "../../components/Footer/Footer";
import TabsNavigation from "../../components/TabsNavigation/TabsNavigation";
import GameTypes from "../../constants/GameTypes";
import { useProviders } from "../../contexts/ProvidersContext";
import { SectionTitle } from "../../styles/Shared.style";
import { useTranslation } from "../../contexts/TranslationContext";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../assets/arrow-right.svg";
import GamesGrid from "../../components/GamesGrid/GamesGrid";
import MainSlider from "../../components/MainSlider/MainSlider";
import { useGamesQuery } from "../../hooks/useQuery/useGamesQuery";

const Main = () => {
	const { selectedProviders } = useProviders();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		data: newGames,
		isFetching: isNewGamesFetching,
		isError: isNewGamesError,
		refetch: refetchNewGames,
	} = useGamesQuery(GameTypes.NEW, selectedProviders, null, 1, 9);

	const {
		data: popularGames,
		isFetching: isPopularGamesFetching,
		isError: isPopularGamesError,
		refetch: refetchPopularGames,
	} = useGamesQuery(GameTypes.POPULAR, selectedProviders, null, 1, 9);

	return (
		<MainContainer>
			<MainSlider />
			<TabsNavigation />
			<GradientGrid>
				<SectionTitle padding="0 16px">
					{t("popular")}{" "}
					<ViewAllBtn onClick={() => navigate(`/games/${GameTypes.POPULAR}`)}>
						<span>{t("all")}</span>
						<img src={ArrowRight} />
					</ViewAllBtn>
				</SectionTitle>
				<GamesGrid
					isLoading={isPopularGamesFetching}
					isError={isPopularGamesError}
					games={popularGames?.data}
					onRetry={refetchPopularGames}
					errorMessage={t("games.popularGamesWillBeHere")}
				/>
			</GradientGrid>
			<GradientGrid>
				<SectionTitle>
					{t("new")}
					<ViewAllBtn onClick={() => navigate(`/games/${GameTypes.NEW}`)}>
						<span>{t("all")}</span>
						<img src={ArrowRight} />
					</ViewAllBtn>
				</SectionTitle>
				<GamesGrid
					isLoading={isNewGamesFetching}
					isError={isNewGamesError}
					games={newGames?.data}
					onRetry={refetchNewGames}
					errorMessage={t("games.newGamesWillBeHere")}
				/>
			</GradientGrid>

			<Footer />
		</MainContainer>
	);
};

export default Main;
