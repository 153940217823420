import styled from "styled-components";
import { colors, sizes } from "../../styles/Variables.style";

export const ProvidersButtonWrap = styled.div`
	padding: 0px ${sizes.padding} 8px;
`;

export const ProvidersButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px;
	background: ${colors.darkGray};
	width: 100%;
	color: white;
	border: none;
	border-radius: 12px;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: left;
	cursor: pointer;
	gap: 8px;
	position: relative;

	&:hover {
		background-color: ${colors.darkGrayActive};
	}
	transition: background-color 0.1s ease, color 0.3s ease, box-shadow 0.3s ease;
`;

export const ProvidersIcon = styled.img`
	width: 20px;
	height: 20px;
`;

export const SelectedProvidersCount = styled.div`
	background-color: #e20000;
	color: white;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 10px;
	font-weight: 500;
	line-height: 14px;
	text-align: center;
	position: absolute;
	top: -3px;
	right: -3px;
`;
