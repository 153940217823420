import styled from "styled-components";
import { colors, fullScreenAreaHeight, fullScreenMargin } from "../../../styles/Variables.style";
import { skeletonAnimation } from "../../../styles/Shared.style";

export const BalancesWrap = styled.div`
	padding: 16px;
	background-color: ${colors.darkGray};
	border-radius: 12px;
`;

export const BalancesContainer = styled.div`
	display: flex;
	gap: 8px;
`;

export const BalanceInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 4px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
`;

export const BalanceInfoTitle = styled.div`
	color: ${colors.lightGray};
`;

export const TonSpaceInfo = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 8px;
	padding: 16px;
	width: 100%;
	gap: 2px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	border-radius: 12px;
	background: linear-gradient(90deg, rgba(248, 52, 52, 0.16) 0%, rgba(248, 52, 52, 0.06) 100%);

	span {
		color: ${colors.lightGray};
		font-weight: 400;
	}
`;

export const BalanceInfoLoading = styled.div`
	height: 18px;
	width: 60px;
	border-radius: 5px;
	${skeletonAnimation};
`;

export const InfoIcon = styled.img`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 16px;
	height: 16px;
`;

export const TonSpaceText = styled.div`
	width: 100%;
	border-radius: 12px;
	background-color: ${colors.darkGray};
`;

export const TonSpaceContentWrap = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100vh - (${fullScreenAreaHeight} + ${fullScreenMargin} + 120px));
	width: 100%;
	padding: 0 16px 32px 16px;
`;

export const TonSpaceFrameWrap = styled.div`
	border-radius: 16px;
	position: relative;
	flex: 1;
`;
export const TonSpaceTextFrame = styled.iframe`
	width: 100%;
	z-index: 0;
	border-radius: 16px;
	height: 100%;
	border: none;
`;

export const TonSpaceTextFrameSkeleton = styled(TonSpaceTextFrame)`
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	${skeletonAnimation};
`;

export const TonSpaceTextButtonWrap = styled.div`
	padding-top: 32px;
`;

export const Button = styled.button`
	padding: 11px 16px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	background-color: ${colors.darkGray};
	color: ${colors.white};
	border: none;
	border-radius: 10px;
	cursor: pointer;
	width: 100%;

	${({ isPrimary }) =>
		isPrimary &&
		`
        background-color: ${colors.white};
        color: #000000;
        box-shadow: 0px 2px 0px 0px rgba(59, 100, 248, 1);
    `}

	transition: background-color 0.3s ease, box-shadow 0.3s ease;
`;
